<template>
    <span class="text-info cursor-pointer" v-b-tooltip.hover="'Éditer'">
        <font-awesome-icon :icon="['far', 'edit']"/>
    </span>
</template>

<script>
    export default {
        name: "TextEditButton"
    }
</script>

<style scoped>
    .cursor-pointer.text-info:hover {
        color: #0fb0cd !important;
    }
</style>